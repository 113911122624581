
import { defineComponent } from "vue";
import BaseHeader2 from "@/components/BaseHeader2.vue";
import TalkroomList from "@/components/talkroom/List.vue";
import { TalkroomListType } from "@/components/talkroom/talkroom";
import { TalkroomListTypeFromJson } from "@/components/talkroom/utils";
import { useStore } from "vuex";
import InfiniteLoading from "v3-infinite-loading";
import "v3-infinite-loading/lib/style.css";
import LoadingIcon from "icons/Loading.vue";

export default defineComponent({
  name: "Talkroom",
  components: {
    BaseHeader2,
    TalkroomList,
    InfiniteLoading,
    LoadingIcon,
  },
  data() {
    const store = useStore();
    return {
      store,
      memberId: null as null | string,
      page: 1,
      isLoading: false,
      infiniteId: +new Date(),
      talkroomList: [] as TalkroomListType,
    };
  },
  methods: {
    infiniteHandler($state: any) {
      $state.loading();
      this.reloadTalkrooms()
        .then((res: any) => {
          $state.loaded();
          if (this.talkroomList.length >= res.data.count) {
            $state.complete();
          }
          this.page += 1;
        })
        .catch(() => {
          $state.loaded();
          $state.complete();
        });
    },
    reloadTalkrooms() {
      this.isLoading = true;
      return this.$axios
        .get("/board", {
          params: {
            member: this.memberId,
            page: this.page,
          },
        })
        .then((res) => {
          const responseData = TalkroomListTypeFromJson(res.data.results);
          if (responseData.length > 0) {
            if (this.page == 1) {
              this.talkroomList = responseData;
            } else {
              this.talkroomList.push(...responseData);
            }
          }
          this.isLoading = false;
          return res;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    if (this.$route.query) {
      this.memberId = (this.$route.query as { member: string }).member;
    }
  },
});
